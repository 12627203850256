:root {
  --surface-a: #ffffff;
  --surface-b: #e8e8e8;
  --surface-c: #bdbdbd;
  --surface-d: #a7a7a7;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #212121;
  --text-color-secondary: #646464;
  --primary-color: #112e51;
  --primary-color-text: #ffffff;
  --font-family: karla, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --surface-0: #ffffff;
  --surface-50: #e8e8e8;
  --surface-100: #d3d3d3;
  --surface-200: #bdbdbd;
  --surface-300: #a7a7a7;
  --surface-400: #919191;
  --surface-500: #7a7a7a;
  --surface-600: #646464;
  --surface-700: #4e4e4e;
  --surface-800: #373737;
  --surface-900: #212121;
  --gray-50: #f1f1f1;
  --gray-100: #dadada;
  --gray-200: #c3c3c3;
  --gray-300: #adadad;
  --gray-400: #959595;
  --gray-500: #7e7e7e;
  --gray-600: #676767;
  --gray-700: #505050;
  --gray-800: #383838;
  --gray-900: #212121;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 4px;
  --surface-ground: #f1f1f1;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #d6d7d9;
  --surface-hover: #e8e8e8;
  --maskbg: rgba(0, 0, 0, 0.4);
  --focus-ring: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}