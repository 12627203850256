/* TYPOGRAHY */
h1 {
  margin: 0;
  font-family: gandhi-bold, serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
}
h2 {
  margin: 0 0 1em 0;
  font-family: gandhi-bold, serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}
h3 {
  margin: 0 0 1em 0;
  font-family: gandhi-bold, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6;
}
h4 {
  margin: 0 1.5em 1em 0;
  font-family: karla, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
h5 {
  margin: 0 1.5em 0.75em 0;
  font-family: gandhi-bold, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
}
h6 {
  margin: 0 1.5em 1em 0;
  font-family: karla, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.5;
  border-bottom: 1px, $base;
}
p {
  color: inherit;
  font-family: gandhi-regular, serif;
  font-size: 16px;
  margin: 0 0 2.4rem 0;
  max-width: 80rem;
  line-height: 2.4;
  width: 100%;
}
p + h1 {
  margin-top: 3rem;
}
p + h2 {
  margin-top: 3rem;
}
p + h3 {
  margin-top: 2rem;
}
a {
  border-bottom: 1px dotted #112e51;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s linear;
}
a:active,
a:hover {
  border-bottom-color: #36bdbb;
}
a img {
  border: none;
}
a.no-decoration {
  border: none !important;
  padding: 0;
}

/* Lists */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul ul {
  margin-left: 2rem;
}
li p:last-child {
  margin-bottom: 0;
}
p + ul {
  margin-top: -2rem;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
input,
label,
select {
  display: block;
}
input[type=file] {
  display:none!important;
}
label {
  margin: 0;
  font-family: karla-bold, sans-serif;
}
input,
select,
textarea {
  background-color: #fff;
  border-color: #aeb0b5;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  font-family: karla, sans-serif;
  font-size: 1.4rem;
  height: 3.6rem;
  line-height: 1.4rem;
  margin: 0;
  padding: 1rem;
  transition: border-color;
  width: 100%;
}
input:focus,
select:focus,
textarea:focus {
  border-color: #112e51;
  color: #112e51;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8, %3Csvg%20%20fill%3D%27%23112e51%27%20width%3D%2210%22%20height%3D%229%22%20viewBox%3D%220%200%2010%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5.706%208.013l3.685-6.234A.851.851%200%200%200%208.664.485H1.283A.852.852%200%200%200%20.55%201.769l3.69%206.244a.852.852%200%200%200%201.466%200z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  width: auto;
  background-color: transparent;

  background-position: right 2rem top 50%;
  background-size: 12px;
  color: #212121;
  border: 2px solid #aeb0b5;
  padding: 0.6rem 3em 0.6rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
select::-ms-expand {
  display: none;
}
select:hover:not([disabled]) {
  background-color: #d6d7d9;
}
textarea {
  resize: vertical;
}
input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
[type='radio'] {
  position: absolute !important;
  top: auto;
  left: -9999px !important;
}
[type='radio'] + label {
  border: 1px solid #aeb0b5;
  background-color: #aeb0b5;
  display: inline-block;
  cursor: pointer;
  line-height: 2rem;
  margin: 0 0 1rem 0.25rem;
  padding: 0.4rem 0.8rem 0.4rem 3rem;
}
[type='radio'] + label:hover {
  background-color: #d6d7d9;
  border-color: #d6d7d9;
}
[type='radio'] + label::before {
  background: #fff;
  border: 2px solid #5b616b;
  border-radius: 2px;
  content: '';
  display: inline-block;
  height: 1.6rem;
  margin-right: 0.6em;
  margin-left: -2.4rem;
  text-indent: 0.15em;
  width: 1.6rem;
  vertical-align: -4px;
}
/*
[type=radio]+label::before{height:1.6rem; width:1.6rem; border-radius:1.6rem}
 */
[type='radio']:checked + label {
  background-color: transparent;
}
[type='radio']:checked + label::before {
  background-color: #5b616b;
  box-shadow: 0 0 0 1.2px #fff inset;
}
[type='radio']:focus + label {
  box-shadow: 0 0 2px 2px #5b9dd9;
}
[type='radio']:disabled + label::before {
  cursor: not-allowed;
}
[type='button'],
[type='submit'],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: 0 0;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-family: karla, sans-serif;
  line-height: 1;
  text-decoration: none;
  user-select: none;
}
[type='button']:disabled,
[type='submit']:disabled,
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
img {
  margin: 0;
  max-width: 100%;
}
html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: inherit;
}
body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
}
@media print {
  footer,
  nav {
    display: none;
  }
}
/* TYPOGRAHY */
